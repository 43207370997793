export default class AddressPage {
  listen() {
    var billingBtn = document.getElementById('address_address_type_billing');
    var shippingCustomerNumberDiv = document.querySelector('.shipping_customer_number');
    var shippingBtn = document.getElementById('address_address_type_shipping');


    if(billingBtn){
      if(billingBtn.checked){
        shippingCustomerNumberDiv.classList.add("hidden");
      }

      billingBtn.addEventListener('change', ()=>{
        shippingCustomerNumberDiv.classList.toggle("hidden");
      });

      shippingBtn.addEventListener('change', ()=>{
        shippingCustomerNumberDiv.classList.remove("hidden");
      });
    }
  }
}