// Migration from redstone v1

export default class SlidePage {
  listen() {
    if ($("#ul_sortable").length>0 ) {
      $( "#ul_sortable" ).sortable({
        group: 'ul_sortable',
        axis: 'y',
        handle: '.handle',
        opacity: 0.8,
        update: function( event, ui ) {
          var order = $( "#ul_sortable" )
          var reorder = []
          var slide_id = 0

          $.each(order[0].children, function(slide_order, slide) {
            slide_id = slide.dataset.id
            reorder[slide_order] = slide_id
          })
          var params = { "slide": reorder}
         $.post($(this).data('update-url'), params);
       }
      });
      $( "#ul_sortable" ).disableSelection();
    }
    $("select.select2").select2({
      minimumInputLength: 3
    });
  }
}

