// Migration from redstone v1

function setCustomerOverride(userId) {
  const clientValue = $(".order-change-select2").val();
  const url = $('#set_customer_override_path').val();

  console.log("sada" + clientValue)
  console.log($(document).getElementById)

  $.ajax({
    url:  url,
    type: 'POST',
    data: {
      user_id: userId 
    },
    success: function (data) {
      window.location.reload(true);
    }
  });
  $(".order-change-select2").val(clientValue);
}

function updateSessionAddressId(addressType, addressId) {
  const url = $('#change_address_path').val();

  $.ajax({
    url:  url,
    type: 'POST',
    data: {
      address_type: addressType,
      address_id: addressId
    },
    success: function (data) {
      // window.location.reload(true);
    }
  });
}

export default class OrderPage {
  listen() {
    $(".order-change-select2").change(function () {
      setCustomerOverride($(this).val());
    });

    $("#order_billing_address_id").change(function () {
      updateSessionAddressId('Billing', $(this).val());
    });

    $("#order_shipping_address_id").change(function () {
      updateSessionAddressId('Shipping', $(this).val());
    });

    $("select.order-change-select2").select2({
      placeholder: "Search for a Customer",
      minimumInputLength: 3}
    )
  }
}
