export default class HeaderPage {
  listen() {
    $("#hamburger").on('click', function() {
      $('.mobile-menu').show()

      $("#wrap").scrollTop(0)
      $(".mobile-menu").velocity({'right': '0'},
      {
        duration: 400,
        easing: "easeOutCirc"
      })
      $('.content-wrap').hide()
      $('.header').hide()
      $('.footer').hide()
    })

    $('.goback').on('click', function(e) {
      var target = $(e.target)
      if(target.hasClass('fix-content-wrap')) { 
        $('.content-wrap').show()
        $('.header').show()
        $('.footer').show()
        $('.mobile-menu').velocity({
          'right': '100%'
          }, {
          duration: 240,
          easing: 'easeInCirc'
        }, $('.mobile-menu').hide())
        
      }else {
        $('.mobile-menu').css({ opacity: 1, right: 0 })

        target.parent().parent().velocity({
          'right': '100%'
        }, {
          duration: 240,
          easing: 'easeInCirc'
        })
      }
    })

    $('a.mobile-inner-link').on('click', function(e) {
      var menu = $( '.inner-mobile-menu.' + $(e.target).data('menu') )

      $('.mobile-menu').velocity({ 
        'right': '100%',
        'opacity': 0
      }, {
        duration: 240,
        easing: 'easeOutCirc'
      })
      
      menu.velocity({
        'right': '0'
      },{
        duration: 240,
        easing: 'easeOutCirc'
      })
    })
  }
}
