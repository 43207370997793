(function($){
  $.fn.limitTextArea = function(limit){

    return this.each(function(eIndex, e){

      var self = $(e);
      var counter = $('<span class="limit-text-area"></span>');

      var update_counter_text = function(chars){
        var remaining = (limit-chars);
        counter.text(remaining+" characters left");
        if(remaining == 0){
          counter.addClass("limit-reached");
        }
        else {
          counter.removeClass("limit-reached");
        }
      };

      counter.insertAfter(self);

      self.keyup(function(event){
        var v = self.val();
        var chars = v.length;
        if(chars > limit) {
          self.val(v.substring(0,limit));
          chars = limit
        }
        update_counter_text(chars)
      });

    });
  };
})(jQuery);
