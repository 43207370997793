// Migration from redstone v1

const cart_select2 = ".search_product_select";
const search_products_url = "/products/search.json";
const search_product_url = "/products/details.json";
const page_limit = 10;

export default class CartPage {
  addProductToCart() {
    var url = "/cart/add_item";

    var quantity = $("#add_product_form #order_item_quantity").val();
    var item_number = $(cart_select2).select2('val');

    $.ajax({
      url: url,
      type: 'POST',
      data: {
        item_number: item_number,
        quantity: quantity
      }
    });
  }

  listen() {
    $('.add-from-cart').on('click', this.addProductToCart);

    $(cart_select2).select2({
      placeholder: "Search for a product",
      minimumInputLength: 3,
      id: function(object){
        return object.item_number;
      },
      ajax: {
        url: search_products_url,
        dataType: 'json',
        quietMillis: 100,
        data: function (term, page) {
          return {
            term: term, //search term
            page_limit: page_limit, // page size
            page: page // page number
          };
        },
        results: function (data, page) {
          //Infinite scrolling
          var more = (page * page_limit) < data.length;
          return {results: data, more: more};
        }
      },
      dropdownCssClass: "bigdrop",
      formatResult: function(object){
        return "["+object.item_number+"] "+object.description;
      },
      formatSelection: function(object){
        return "["+object.item_number+"] "+object.description;
      },
      initSelection: function(element, callback) {
        var id = $(element).val();
        if (id !== "") {
          $.ajax(search_product_url, {
            data: {
              id: id
            }
          }).done(function(data){
            callback(data);
          });
        }
      },
      escapeMarkup: function (m) { return m; }
    });
  }
}
