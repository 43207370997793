import Glide, { Controls, Breakpoints, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'

export default class SlideCarouselPage {
  listen() {
    $('.glide__arrow').on('click touchstart', function(e){
      e.preventDefault();
    });
    var glideCount = $('.glide')

    if (glideCount.length > 0) {
      if (glideCount[0].childNodes.length > 0) {
        var glide = new Glide('.glide', {
          type: 'carousel',
          focusAt: 'center',
          autoplay: 5000,
          gap: 0,
          hoverpause: false,
          breakpoints: {
            2800: {
              peek: 670
            },
            2000: {
              peek: 370,
            },
            1700: {
              peek: 100
            },
            800: {
              perView: 1,
              peek: 0,
              swipeThreshold: false,
            }
          }
        })
        
        glide.mount({ Controls, Breakpoints, Autoplay })
      }
    }
  }
}
