// Migration from redstone v1

export default class CategoryPage {
  listen() {
    if ($("#ul_sortable").length > 0 ) {
      $("#ul_sortable").sortable({
        group: 'ul_sortable',
        axis: 'y',
        handle: '.handle',
        opacity: 0.8,
      });
      $('#ul_sortable').on('sortupdate',function(){
        var order = $("#ul_sortable")
        var reorder = []
        var category_id = 0
        $.each(order[0].children, function(category_order, category) {
          category_id = category.dataset.id
          reorder[category_order] = category_id
        })
        var params = { "display_category": reorder };
        $.post($(this).data('update-url'), params);
     });
      $("#ul_sortable").disableSelection();
    }
    $("select.select2").select2({
      minimumInputLength: 3
    });
    $(document).on("change",".category-filter",function(e){
      window.location.href= $(this).val();
    });
  }
}

