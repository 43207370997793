// Migration from redstone v1
// TODO: Refactor it

function toggleBubble(input, true_value){
  if (input.val() == true_value) {
    hideBubble(input);
  }

  else {
    showBubble(input);
  }
}

function showBubble(input){
  var bubble = $('.' + input.attr('rel'));
  bubble.data('remote', input);
  bubble.show();
  updateBubbleOffsets();
}

function hideBubble(input){
  var bubble = $('.' + input.attr('rel'));
  bubble.fadeOut();
}

function updateBubbleOffsets() {
  $('.register-form__help').each(function(){
    var bubble = $(this);
    var remote = bubble.data('remote');
    if (remote) {
      var new_offset = bubble.offset();
      new_offset.top = remote.offset().top-18;
      bubble.offset(new_offset);
    };
  });
}

export default class AccountPage {
  listen() {
    $('#user_has_residential_shipping_address_input input[type=radio]').change(function(){
      toggleBubble($(this), "true");
    });

    $("#user_resale_number_state_id").change(function(){
      var e = $(this);
      toggleBubble(e, "43");

      var remote = $('#user_resale_number');
      remote.unbind('blur');

      // only hide tx resale_number field after it's been filled out
      if (e.val() == "43") {
        remote.blur(function(){
          if ($(this).val().length > 0) {
            hideBubble(e);
          }
          else {
            showBubble(e);
          }
        });
      }

    });

    // show referrer help user selects an option that requires additional input
    $("#user_referrer_details").focus(function(){
      var e = $(this);
      if( ! e.is(".no-help")) {
        showBubble(e);
      }
    });

    // hide referrer help when user moves to next field
    $("#user_referrer_details").blur(function(){
      var e = $(this);
      hideBubble(e);
    });

    // special delivery instructions
    $('#user_shipping_delivery_instructions')
      .focus(function(){
        showBubble($(this));
      })
      .blur(function(){
        hideBubble($(this));
      })
    ;

    // identical shipping address
    $('.user_has_identical_shipping_address input[type=radio]').change(function(){
      var e = $(this);
      var fields = $('#shipping-address-fields');

      // hide fields
      if (e.val() == "true") {
        fields.fadeOut(function(){
          fields.find(':input').attr('disabled', 'disabled');
          updateBubbleOffsets();
        });
      }

      // show fields
      else {
        fields.find(':input').removeAttr('disabled');
        fields.fadeIn(function(){
          updateBubbleOffsets();
        });
      }
    });


    // dynamically hide form fields for migrating customers
    $(".user_is_migrant input[type=radio]").change(function(){
      var v = $(this).val();
      var a = $(".new-customer");
      var b = $(".migrant-customer");
      var help = $('.register-form__help');
      $('.migrant-customer').slideUp()
      console.log( v );
      if(v == "true"){
        help.addClass('sudo-hidden');
        $(a).slideUp(function(){
          b.show();
          updateBubbleOffsets();
        });
      }

      else if(v == "false") {
        help.removeClass('sudo-hidden');
        a.slideDown(function(){
          b.hide();
        });
      }
    });

    $("#user_about, #user_interests").limitTextArea(400);
  }
}
