// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("jquery");
require('intersection-observer');
require("./plugins/jquery/limit_text_area");
require('select2');
require("./plugins/sortable/jquery-ui");
require("jquery-colorbox");
require("velocity-animate");

import lozad from 'lozad';
import AccountPage from './pages/account_page';
import CartPage from './pages/cart_page';
import CategoryPage from './pages/category_page';
import SlidePage from './pages/slide_page';
import SlideCarouselPage from './pages/slide_carousel_page';
import HeaderPage from './pages/header_page';
import OrderPage from './pages/order_page';
import AddressPage from './pages/address_page';

(function ($) {
  $(document).on("turbolinks:before-cache", function() {
    $('.select2-input').select2('destroy');
    $('.search_product_select').select2('destroy');
  });

  $(document).on('turbolinks:load', function () {

    new AccountPage().listen();
    new CartPage().listen();
    new CategoryPage().listen();
    new SlidePage().listen();
    new HeaderPage().listen();
    new OrderPage().listen();
    new SlideCarouselPage().listen();
    new AddressPage().listen();

    // Lazy loading images
    const observer = lozad();
    observer.observe();

    $('.customer-id-override').on('click', function (e) {
      e.preventDefault();
      const userId = $(e.target).attr("data-userid");
      $.ajax({
        url: 'orders/set_customer_override',
        type: 'POST',
        data: {
          user_id: userId
        },
        success: function(data) {
          $('#shop' + userId).html("Customer Override Successful!");
          $('.customer_override').remove();
          $('body').append("<div class='customer_override'>Shopping for: " + $('#business' + userId).html() + " <div id='cancel_override'><a href='#' id='cancel_override_link' title='Click to Cancel Customer Override'>X</a></div></div>");
          activateCancelOverrideLink();
        }
      });
    });

    function activateCancelOverrideLink() {
      $('#cancel_override_link').on('click', function (e) {
        if (confirm('Cancel customer override?'))
          $.ajax({
            url: 'orders/cancel_customer_override',
            type: 'POST',
            data: {
              user_id: 0
            },
            success: function(data) {
              location.reload();
            }
          });
      });
    }
    activateCancelOverrideLink();

    // General Legacy Js
    $('.scroll-down').on('click', function () {
      $("html, body").animate({ scrollTop: "475px" });
    });

    $('.add-to-order').on('click', function (e) {
      $(this).attr('value', 'Added')
    });

    $('.quick-add-item').on('click', function () {
      $(this).attr('value', 'Adding...').addClass('working');
    });

    $('.content-wrap').css({ 'opacity': '1' });
  });
})(jQuery);
